<template>
  <div class="testPage">
    <KcSelect left="20" top="40" @popupVal="popupVal"></KcSelect>

    <KcSelect isBlue keyLabel="haripinType" :optionData="optionData" left="20" top="150" @popupVal="popupVal2"></KcSelect>
    <div class="name"> 我是大好人 </div>
  </div>
</template>

<script>

export default {
  name: 'testPage',
  data() {
    return {
      optionData: [
        {name: "个体工商户", haripinType: 1},
        {name: "其他发卡企业", haripinType: 2},
        {name: "集团发卡企业", haripinType: 3},
        {name: "品牌发卡企业", haripinType: 4},
        {name: "规模发卡企业", haripinType: 5},
      ],
    }
  },
  components: {},
  methods: {
    popupVal(val,row) {
      console.log(val)
      console.log(row)
    },
    popupVal2(val) {
      console.log("val=====", val)
    }
  }
}
</script>
<style lang="scss" scoped>
 .name{
   font-size: 8px;
 }
.testPage {
  //height: 500px;
  //background: #ccc;
}
</style>
